body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  color: rgb(11, 63, 11);
  font-family: "Fredoka", sans-serif;
  align-content: center;
  align-items: center;
  background-image: linear-gradient(
    to right,
    #fdf7ff,
    #fff5fb,
    #fff4f2,
    #fff5e9,
    #fff8e4,
    #f9fae3,
    #f2fbe3,
    #e9fde6,
    #defbed,
    #d8f7f3,
    #d7f3f7,
    #daeef7
  );
  background-size: 280%;
  -webkit-animation: gradient 15s ease infinite;
          animation: gradient 15s ease infinite;
}

.photo {
  display: flex;
  height: 840px;
}

.Inspired {
  height: 250px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  padding: 2rem;
  font-size: calc(1.375rem + 1.5vw);
  color: rgb(255, 255, 255);
}
.List {
  height: 250px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  padding: 2rem;
  font-size: calc(1.375rem + 1.5vw);
  color: rgb(255, 255, 255);
}
.footer {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: right;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  color: rgb(220, 194, 194);
  right: -590px;
}

.about {
  display: grid;
  margin-top: 10%;
  text-align: center;
  background-color: transparent;
  padding: 2rem;
  font-size: calc(1.375rem + 1.5vw);
  color: rgb(220, 194, 194);
}
.aboutinfo {
  flex-direction: column;
}
.project {
  margin-top: 40%;
}
p {
  text-align: center;
  color: rgb(220, 194, 194);
}

@media screen and (max-width: 480px) {
  .about {
    background-color: transparent;
  }
  .App {
    color: rgb(11, 63, 11);
    font-family: "Fredoka", sans-serif;
    align-content: center;
    align-items: center;
    background-image: linear-gradient(
      to right,
      #fdf7ff,
      #fff5fb,
      #fff4f2,
      #fff5e9,
      #fff8e4,
      #f9fae3,
      #f2fbe3,
      #e9fde6,
      #defbed,
      #d8f7f3,
      #d7f3f7,
      #daeef7
    );
    background-size: 300% 300%;
    -webkit-animation: gradient 15s ease infinite;
            animation: gradient 15s ease infinite;
  }
}

.socials {
  bottom: 44px;
  position: absolute;
  width: 100%;
  text-align: center;
  align-self: flex-end;
  right: -590px;
}

